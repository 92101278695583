import firstPhoto from "../images/clients-photos/1.PNG";
import secondPhoto from "../images/clients-photos/2.PNG";
import thirdPhoto from "../images/clients-photos/3.PNG";
import fourthPhoto from "../images/clients-photos/4.PNG";
import fifthPhoto from "../images/clients-photos/5.PNG";
import sixthPhoto from "../images/clients-photos/6.PNG";

export const reviews = [
  {
    name: "Алексей",
    photo: firstPhoto,
    post: 'Ведущий специалист по Digital и рекламе',
    review: `Работал с Андреем неоднократно, ни разу не дал повода 
    усомниться в своих профессиональных и человеческих качествах. 
    В спартанских условиях, в грязь, холод и ураган все задачи выполнялись 
    быстро и качественно. Что касается оформления необходимых договоров 
    для работы с юр.лицами - тоже без нареканий, все счета и подписанные 
    документы всегда готовились оперативно. Отдельно хочу 
    поблагодарить за терпение и профессионализм - в случае, если я 
    чего-то не понимал или предлагал полную ерунду, Андрей терпеливо 
    и вежливо объяснял, как будет сделать правильнее.`,
  },
  {
    name: "Артём Лодвиг",
    photo: secondPhoto,
    post: 'Генеральный директор компании "Мастера Ремонта"',
    review: `Андрей, спасибо большое за видео)

    Помню как мы начинали 7 лет назад, учились, разивавились и сейчас есть огненный результат)
    Я даже не помню сколько видео мы сделали но было весело)
    
    Спасибо за работу, идем дальше 💪😎`,
  },
  {
    name: "Анастасия Давиденко",
    photo: thirdPhoto,
    post: 'Ведущий дизайнер студии "Адельхейд"',
    review: `Андрей, спасибо большое за видео! Все как всегда 
    оперативно и качественно. Пару правок буквально и можно 
    выкладывать для промо. По композиции и хронометражу все 
    супер тоже, ничего не нужно менять. 
    Спасибо) `,
  },
  {
    name: "Андрей",
    photo: fourthPhoto,
    post: 'Руководитель "Simtu"',
    review: `Всем привет! Знаю Андрея уже 5,5 лет! Раньше он работал в 
    нашей группе компании видеографом, 1,5 года назад стал аутсорсером. 
    Мы аутсорсим у него часть видео и фото продакшена.
    Из преимуществ: четкость, ответственность, фото и видео отличного 
    качества от одного человека. Рекомендую!`,
  },
  {
    name: "Александр Безносенко",
    photo: fifthPhoto,
    post: 'Основатель компании "Заборы 2.0"',
    review: `В прошлом году запускал сайт, нужен был контент, 
    обратился к Андрею. Снимали на каждом объекте видео отзыв + 
    фотографии + обязательно с коптера. До этого я никогда не 
    снимался, но получилось все очень круто, большое спасибо, 
    будем работать дальше.`,
  },
  {
    name: "Сергей Петров",
    photo: sixthPhoto,
    post: 'Генеральный директор компании "РемонтКварт"',
    review: ` С Андреем У. сотрудничаем с 2017 года, 
    вместе росли, развивались  в творческом направлении. 
    Отснято более 50 объектов, порядка 1500 фотоматериала 
    сделанных ремонтов.  Творческий подход, подскажет и 
    поможет, где как лучше, какие то новые идеи. Важный 
    момент - сдержанный,  пунктуален, исполнительный, 
    креативный. На данный момент снимаем отзывы, 
    обзоры  с заказчиками, "фоткаем" выполнение работ,  
    по советовал в свое время партнерам, знаю сейчас с 
    ним работают. Так держать!!`,
  },
]